import {CButton} from "@coreui/react";
import {email_restrictions} from "constants";
import {formatDate} from "../../../../Utils/DateTimeUtils";

export const roleColumns = [
    {
        name: 'Name',
        selector: row => row.name,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" href={`/business/customers/membership-types/${row.id}`} target="_blank">
                    Edit
                </CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    },
];

export const membershipColumns = (onEditButtonClick, onDeleteButtonClick) => [
    {
        name: 'Customer Email',
        selector: row => row.email,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Membership Type',
        selector: row => row.membership_type?.name,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Expiration Date',
        selector: row => row.expire_on,
        format: row => formatDate(row.expire_on) || "N/A",
        width: "250px",
        wrap: true,
        sortable: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => onEditButtonClick(row)}>
                    Edit
                </CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="danger" className="white" onClick={() => onDeleteButtonClick(row.id)}>
                    Delete
                </CButton>
            </div>
        ),
        width: "105px",
        right: true,
    }
];

export const membershipFilterCategories = [
    {
        name: 'Customer Email',
        category: 'email',
        type: 'exact',
    },
    {
        name: 'Membership Type',
        category: 'membership_types',
        type: 'multiple',
    },
    {
        name: 'Expiration Date',
        category: ['min_expiration_date', 'max_expiration_date'],
        type: 'range',
    },
];

export const applyMembershipFilters = (memberships, filters) => {
    return memberships.filter(entry => {
        if (filters.search) {
            const searchTerm = filters.search?.toLowerCase();
            if (!(entry.email?.toLowerCase().includes(searchTerm))) {
                return false;
            }
        }

        if (filters.membership_types?.length) {
            if (!filters.membership_types.map(role => role.value).includes(entry.membership_type_id)) return false;
        }

        if (filters.email) {
            const searchTerm = filters.email?.toLowerCase();
            if (!entry.email?.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.min_expiration_date) {
            if (entry.expire_on && entry.expire_on < filters.min_expiration_date) return false;
        }

        if (filters.max_expiration_date) {
            if (!entry.expire_on || entry.expire_on > filters.max_expiration_date) return false;
        }

        return true;
    });
};
