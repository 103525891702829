import React, { useEffect, useState } from "react";
import { CForm, CCol, CFormInput, CFormLabel } from "@coreui/react";
import RecommendedVenues from "../Layout/Home/RecommendedVenues";
import { useDispatch, useSelector } from "react-redux";
import {
    checkAvailability,
    getPublicProviderProfile,
    getPublicProviderVenueListing,
    getAllAmenities,
} from "../../redux/action/action";
import { getAvailableSlots } from "../../Utils/DateTimeUtils";
import RoundedMultiSelect from "../components/RoundedMultiSelect";

const ProviderVenues = () => {
    const dispatch = useDispatch();
    const slotAlready = useSelector(state => state.Apis.slotAlreadyExist);
    const venuesData = useSelector(state => state.Apis.providerVenuesListing);
    const { name, role } = useSelector(state => state.Login.userDetail);
    const allAmenities = useSelector(state => state.Apis.allAmenities);

    const [venues, setVenues] = useState([]);
    const [filters, setFilters] = useState({});

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    };

    const updateFiltersMultiselect = (selectedOptions, actionMeta) => {
        setFilters({...filters, [actionMeta.name]: selectedOptions});
    };

    const fetchAvailability = (venues, date) => {
        if (venues && date) {
            const venuesInfo = {
                venueIds: venues.map((venue) => venue.id),
                date: date,
            };
            dispatch(checkAvailability(venuesInfo));
        }
    }

    const applyFilters = (venues, filters) => {
        return venues.filter((venue, index) => {
            if (filters.venueName) {
                const searchTerm = filters.venueName.toLowerCase();
                if (!(venue.name.toLowerCase().includes(searchTerm))) {
                    return false;
                }
            }

            if (filters.capacity) {
                // If max_attendees is null, that means the capacity is undefined and considered unlimited.
                if (venue.max_attendees != null && parseInt(venue.max_attendees) < parseInt(filters.capacity)) {
                    return false;
                }
            }
    
            if (filters.amenities?.length) {
                const venueAmenities = venue.amenities.split(",");
                for (let i = 0; i < filters.amenities.length; i++) {
                    if (!venueAmenities.includes(filters.amenities[i].label)) {
                        return false;
                    }
                }
            }

            if (filters.date) {
                let venueStartTime;
                let venueEndTime;
                const date = new Date(filters.date);
                switch (date.getUTCDay()) {
                    case 0:
                        venueStartTime = venue.start_time_sun;
                        venueEndTime = venue.end_time_sun;
                        break;
                    case 1:
                        venueStartTime = venue.start_time_mon;
                        venueEndTime = venue.end_time_mon;
                        break;
                    case 2:
                        venueStartTime = venue.start_time_tue;
                        venueEndTime = venue.end_time_tue;
                        break;
                    case 3:
                        venueStartTime = venue.start_time_wed;
                        venueEndTime = venue.end_time_wed;
                        break;
                    case 4:
                        venueStartTime = venue.start_time_thu;
                        venueEndTime = venue.end_time_thu;
                        break;
                    case 5:
                        venueStartTime = venue.start_time_fri;
                        venueEndTime = venue.end_time_fri;
                        break;
                    case 6:
                        venueStartTime = venue.start_time_sat;
                        venueEndTime = venue.end_time_sat;
                        break;
                    default:
                        break;
                }
                const availableSlots = getAvailableSlots(
                    venueStartTime,
                    venueEndTime,
                    filters.date,
                    slotAlready.length > index ? slotAlready[index] : [],
                    venue.min_hours,
                    venue.hours_between_events
                );
                if (availableSlots.startTimes.length === 0 && availableSlots.endTimes.length === 0) {
                    return false;
                }
            }
    
            return true;
        });
    };

    useEffect(() => {
        dispatch(getAllAmenities());
    }, []);

    useEffect(() => {
        fetchAvailability(venuesData, filters.date)
    }, [filters.date]);

    useEffect(() => {
        setVenues(applyFilters(venuesData, filters));
    }, [venuesData, filters, slotAlready]);

    useEffect(() => {
        const sendData = {
            businessId: document.location.pathname.split("/")[2],
        };
        if (localStorage.getItem("userId") !== "undefined") {
            sendData.userId =  localStorage.getItem("userId");
        }
        dispatch(getPublicProviderVenueListing(sendData));
        dispatch(getPublicProviderProfile(sendData));
    }, [role, name, document.location.pathname]);

    return (
        <>
            <div class="container pt-2">
                <CForm className="row w-100 gy-2 gx-3 align-items-end py-3">
                    <CCol className="col-6 col-md-3">
                        <CFormLabel htmlFor="venueName" className="mb-1">Name</CFormLabel>
                        <CFormInput id="venueName" name="venueName" placeholder="Search..." 
                            style={{borderRadius: "2rem"}} onChange={updateFilters}
                        />
                    </CCol>
                    <CCol className="col-6 col-md-2">
                        <CFormLabel htmlFor="capacity" className="mb-1">Capacity</CFormLabel>
                        <CFormInput id="capacity" name="capacity" placeholder="# of guests" 
                            style={{borderRadius: "2rem"}} onChange={updateFilters}
                        />
                    </CCol>
                    <CCol className="col-6 col-md-5">
                        <CFormLabel htmlFor="amenities" className="mb-1">Amenities</CFormLabel>
                        <RoundedMultiSelect
                            name="amenities"
                            onChange={updateFiltersMultiselect}
                            options={allAmenities.map(listing => ({
                                label: listing.name,
                                value: listing.id,
                            }))}
                        />
                    </CCol>
                    <CCol className="col-6 col-md-2">
                        <CFormLabel htmlFor="date" className="mb-1">Date</CFormLabel>
                        <CFormInput id="date" name="date" type="date" 
                            style={{borderRadius: "2rem"}} onChange={updateFilters}
                        /> 
                    </CCol>
                </CForm>
            </div>
            <div className="px-5">
                <hr/>
            </div>
            <RecommendedVenues
                dispatch={dispatch}
                venues={venues}
                setVenues={setVenues}
                removeVenue={false}
                />
        </>
    )

}

export default ProviderVenues;
