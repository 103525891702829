import React, { useEffect, useState } from "react";
import {CButton} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import {getPublicProviderProfile, SITE_URL} from "../../redux/action/action";
import BannerSection from "../Layout/Home/BannerSection";
import ErrorPage from "../../ErrorPage";
import Programs from "./Programs"
import ProviderVenues from "./ProviderVenues";
import {resetNavbarBrand, updateNavbarBrand} from "../../redux/action/accountModals";

const OrganizationHome = () => {
    const dispatch = useDispatch();

    const providerDetail = useSelector(state => state.Apis.providerDetail);
    const providerDetailSuccess = useSelector(state => state.Apis.providerDetailSuccess);
    const { name, role } = useSelector(state => state.Login.userDetail);
    
    const [providerProfile, setProviderProfile] = useState({});
    const [programsModuleVisible, setProgramsModuleVisible] = useState(true);

    const onClick = (state) => {
        setProgramsModuleVisible(state);
    }

    useEffect(() => {
            if (providerDetailSuccess) {
                setProviderProfile(providerDetail);
                document.title = `${providerDetail.name} | DoubleSpot`;
                document.querySelector('meta[name="description"]').setAttribute(
                    "content",
                    `Explore what ${providerDetail.name} has to offer on DoubleSpot.`
                );
                const brandLogo = providerDetail.business_plan?.brand_logo;
                dispatch(updateNavbarBrand({
                    navbarLogo: brandLogo ? `/images/${brandLogo}` : "",
                    navbarColor: providerDetail.business_plan?.brand_color,
                    navbarButtonColor: providerDetail.business_plan?.brand_button_color,
                    navbarButtonWhiteText: providerDetail.business_plan?.brand_button_white_text,
                }));
            } else if (providerDetailSuccess === false) {
                setProviderProfile({notFound: true});
            }
    
            return () => {
                dispatch(resetNavbarBrand());
            }
        }, [providerDetailSuccess]);

    useEffect(() => {
        const sendData = {
            businessId: document.location.pathname.split("/")[2],
        };
        if (localStorage.getItem("userId") !== "undefined") {
            sendData.userId =  localStorage.getItem("userId");
        }
        dispatch(getPublicProviderProfile(sendData));
    }, [name, role, document.location.pathname]);

    if (providerProfile.notFound) {
        return <ErrorPage/>
    }



    return (
        <>
            <BannerSection
                title={providerProfile.name}
                imageSource={SITE_URL + 'images/' + providerProfile?.images}
                />
            {providerProfile?.business_plan?.programs ? 
                <div class="container pt-4 flex-row">
                    <CButton className="ps-0 text-button"><h2 
                                className="section_heading pb-0" 
                                onClick={() => onClick(true)} 
                                style={{color: programsModuleVisible? "black" : "gray"}}
                                >Programs</h2></CButton>
                    <div class="vr mt-1" style={{height: "2.5em", width: "2px"}}></div>
                    <CButton className="text-button"><h2 
                                className="section_heading pb-0" 
                                onClick={() => onClick(false)}
                                style={{color: programsModuleVisible? "gray" : "black"}}
                                >Rentals</h2></CButton>
                </div> : 
                null
            }
            {programsModuleVisible && providerProfile?.business_plan?.programs ? 
               <Programs/> : <ProviderVenues/>
            }

        </>
    )

}

export default OrganizationHome;
