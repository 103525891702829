import BusinessSidebar from "../components/Sidebar";
import React, {useState} from "react";

const DashboardLayout = ({ children }) => {
    const [sidebarNarrowed, setSidebarNarrowed] = useState(false)

    return (
        <>
            <aside className="sidebar-container">
                <BusinessSidebar setSidebarNarrowed={setSidebarNarrowed}/>
            </aside>
            <main className={`main-content-container flex-row ${sidebarNarrowed ? "sidebar-narrowed" : "sidebar-expanded"}`}>
                {children}
            </main>
        </>
    )
};

export default DashboardLayout;
