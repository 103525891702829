import { SITE_URL } from "../redux/action/action";

/**
 * This is a wrapper for href links to be navigated via React's navigate function.
 * @param navigate The React navigate function created by calling useNavigate().
 * @returns The event handler for an href click and navigate event.
 */
export const hrefNav = (navigate) => {
    return e => {
        e.preventDefault();
        navigate(e.target.getAttribute('href'));
    };
}

/**
 * 
 * @param {string} profileImage The path of the profile image. Can be a file name, which indicates an uploaded file, or an HTTP(S) image URI, which indicates that the profile image should be externally fetched and rendered.
 * @param {string} assetsLocation Relative path to the assets directory. The path should include "assets" but not the trailing slash.
 * @returns The profile image URI to be used as source in an <img> element.
 */
export const getProfilePictureUri = (profileImage, assetsLocation) => {
    try {
        new URL(profileImage);
        return profileImage;
    } catch (error) {
        return profileImage ? `${SITE_URL}images/${profileImage}` : `${assetsLocation}/images/user_profile.png`;
    }
}
