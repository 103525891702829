import {CButton} from "@coreui/react";

export const columns = (onEditButtonClick, onDeleteButtonClick) => [
    {
        name: 'Venue',
        selector: row => row?.venue?.name,
        width: "250px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Free Attendees',
        selector: row => row?.process_payment ? row?.free_attendees : "N/A",
        width: "150px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Per Attendee Price',
        selector: row => row?.process_payment ? `$${row?.price_per_attendee}` : "N/A",
        width: "150px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Hourly Price',
        selector: row => row?.process_payment ? `$${row?.hourly_price}` : "N/A",
        width: "150px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Cleaning Fee',
        selector: row => row?.process_payment ? `$${row?.cleaning_fee}` : "N/A",
        width: "150px",
        wrap: true,
        sortable: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => onEditButtonClick(row)}>Edit</CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="danger" className="white" onClick={() => onDeleteButtonClick(row.id)}>
                    Delete
                </CButton>
            </div>
        ),
        width: "105px",
        right: true,
    }
];
