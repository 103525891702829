import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSwitch,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {addMemberPrices, updateMemberPrice} from "../../../../redux/action/action";
import React from "react";

const EditMemberPricingModal = ({visible, onClose, editFormData, setEditFormData, venues, allPricings, dispatch}) => {
    const updateFormData = (event) => {
        setEditFormData({...editFormData, [event.target.name]: event.target.value});
    }

    const updateFormDataCheckbox = (event) => {
        setEditFormData({...editFormData, [event.target.name]: event.target.checked});
    }

    const updateFormDataMultiselect = (selectedOptions, actionMeta) => {
        setEditFormData({...editFormData, [actionMeta.name]: selectedOptions});
    }

    const onSubmit = (event) => {
        event.preventDefault();
        const submission = {
            ...editFormData,
            free: undefined,
            process_payment: !editFormData?.free,
        };
        if (editFormData?.id === null) {
            dispatch(addMemberPrices({
                ...submission,
                id: undefined,
                venues: undefined,
                venueIds: editFormData.venues.map(venue => venue.value),
            }));
        } else {
            dispatch(updateMemberPrice(submission));
        }
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={() => {
            onClose();
            setEditFormData({});
        }}>
            <CModalHeader>
                <CModalTitle>{editFormData?.id === null ? "Add" : "Edit"} Member Pricing</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={onSubmit}>
                    <div class="pb-3">
                        {
                            editFormData.id === null ?
                                <div className="pb-3">
                                    <CFormLabel htmlFor="venues">Venues</CFormLabel>
                                    <MultiSelectCheckbox
                                        name="venues"
                                        onChange={updateFormDataMultiselect}
                                        options={venues
                                            .filter(
                                                venue => venue.process_payment && !allPricings.map(pricing => pricing.venue_id).includes(venue.id)
                                            )
                                            .map(
                                                venue => ({
                                                    label: venue.name,
                                                    value: venue.id,
                                                })
                                            )
                                        }
                                        value={editFormData.venues}
                                    />
                                </div> : null
                        }
                        <div class="flex-row">
                            <CFormLabel className="pe-3" htmlFor="free">Free Of Charge</CFormLabel>
                            <CFormSwitch name="free" checked={editFormData?.free} onChange={updateFormDataCheckbox}/>
                        </div>
                        {
                            !editFormData?.free ? <>
                                <div className="py-3">
                                    <CFormLabel htmlFor="free_attendees">Attendees Allowed Without Additional
                                        Cost</CFormLabel>
                                    <div className="flex-row align-items-center">
                                        <div className="px-2">
                                            <CFormInput type="number" required min="0" name="free_attendees"
                                                        onChange={updateFormData}
                                                        value={editFormData.free_attendees}
                                                        style={{width: "100px"}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-3">
                                    <CFormLabel htmlFor="price_per_attendee">Price Per Additional Attendee</CFormLabel>
                                    <div className="flex-row align-items-center">
                                        <span>$</span>
                                        <div className="px-2">
                                            <CFormInput type="number"
                                                        required
                                                        min="0"
                                                        step="0.01"
                                                        name="price_per_attendee"
                                                        onChange={updateFormData}
                                                        value={editFormData.price_per_attendee}
                                                        style={{width: "100px"}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-3">
                                    <CFormLabel htmlFor="hourly_price">Hourly Price</CFormLabel>
                                    <div className="flex-row align-items-center">
                                        <span>$</span>
                                        <div className="px-2">
                                            <CFormInput type="number"
                                                        required
                                                        min="0"
                                                        step="0.01"
                                                        name="hourly_price"
                                                        onChange={updateFormData}
                                                        value={editFormData.hourly_price}
                                                        style={{width: "100px"}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-3">
                                    <CFormLabel htmlFor="cleaning_fee">Cleaning Fee</CFormLabel>
                                    <div className="flex-row align-items-center">
                                        <span>$</span>
                                        <div className="px-2">
                                            <CFormInput type="number"
                                                        required
                                                        min="0"
                                                        step="0.01"
                                                        name="cleaning_fee"
                                                        onChange={updateFormData}
                                                        defaultValue={editFormData.cleaning_fee}
                                                        style={{width: "100px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </> : null
                        }
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    )
};

export default EditMemberPricingModal;
