import {
    API_PACKAGES_SUCCESS,
    AUTH_RESET_SUCCESS,
    BOOK_VENUE_SUCCESS,
    BOOK_VENUE_FAIL,
    BIZ_DELETE_SUCCESS,
    CALENDAR_DATA_SUCCESS,
    CONFIRM_EVENT_SUCCESS,
    CURRENT_BOOKINGS_SUCCESS,
    ALL_AMENITIES_SUCCESS,
    GET_VENUES_LIST_SUCCESS,
    MANAGE_STATUS_BOOKING,
    SEARCH_DATA_SUCCESS,
    GET_WISHLIST_SUCCESS,
    PROVIDER_VENUE_LISTING_SUCCESS,
    CHAT_NOTES_SUCCESS,
    OVERALLSTATUS_SUCCESS,
    SLOT_ALREADY_EXIST_SUCCESS,
    EACH_VENUE_DETAIL_SUCCESS,
    DRAFT_EVENT_SUCCESS,
    SINGLE_VENUE_REVIEW_SUCCESS,
    GET_DRAFT_EVENT_SUCCESS,
    RESET_PWD_SUCCESS,
    SINGLE_VENUE_SUCCESS,
    UPDATE_VENUE_SUCCESS,
    APPLYCOUPON_SUCCESS,
    PAYMENTSUCCEED_SUCCESS,
    GET_NOTIFICATION_SUCCESS,
    GET_ALL_DRAFT_VENUES_SUCCESS,
    APPLYCOUPON_CLEAR,
    WALLET_SUCCESS,
    WALLET_HISTORY_SUCCESS,
    BANK_SUCCESS,
    SET_RESET_PWD_FALSE_SUCCESS,
    ADD_WISHLIST_SUCCESS,
    GET_USER_PROFILE_SUCCESS,
    DECLINE_EVENT_SUCCESS,
    CALENDAR_RESET_SUCCESS,
    CUSTOMER_LIST_SUCCESS,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    EVENT_TYPES_SUCCESS,
    UPDATE_EVENT_TYPE_SUCCESS,
    UPDATE_EVENT_TYPE_FAIL,
    ADD_EVENT_TYPE_SUCCESS,
    ADD_EVENT_TYPE_FAIL,
    DELETE_EVENT_TYPE_SUCCESS,
    GET_PROVIDER_PROFILE_SUCCESS,
    GET_PROVIDER_PROFILE_FAIL,
    GET_MANAGERS_SUCCESS,
    UPDATE_MANAGER_ACCESS_SUCCESS,
    UPDATE_MANAGER_ACCESS_FAIL,
    WHITELIST_SUCCESS,
    DOMAIN_SUCCESS,
    ADD_WHITELIST_ENTRY_SUCCESS,
    ADD_WHITELIST_ENTRY_FAIL,
    DELETE_WHITELIST_ENTRY_SUCCESS,
    DELETE_WHITELIST_ENTRY_FAIL,
    GET_BUSINESS_PLAN_SUCCESS,
    GET_BUSINESS_PLAN_FAIL,
    EVENT_TAGS,
    EVENT_TAGS_SUCCESS,
    UPDATE_EVENT_TAG_SUCCESS,
    UPDATE_EVENT_TAG_FAIL,
    ADD_EVENT_TAG_SUCCESS,
    ADD_EVENT_TAG_FAIL,
    DELETE_EVENT_TAG,
    DELETE_EVENT_TAG_SUCCESS,
    RESERVATION_REPORTS_SUCCESS,
    ADD_RESERVATION_REPORT_SUCCESS,
    UPDATE_RESERVATION_REPORT_SUCCESS,
    DELETE_RESERVATION_REPORT_SUCCESS,
    MEMBERSHIP_TYPES_SUCCESS,
    ADD_MEMBERSHIP_TYPE_SUCCESS,
    UPDATE_MEMBERSHIP_TYPE_SUCCESS,
    DELETE_MEMBERSHIP_TYPE_SUCCESS,
    MEMBERSHIPS_SUCCESS,
    ADD_MEMBERSHIPS_SUCCESS,
    UPDATE_MEMBERSHIP_SUCCESS,
    DELETE_MEMBERSHIP_SUCCESS,
    IMPORT_MEMBERSHIPS_SUCCESS,
    EVENT_DETAIL_SUCCESS,
    EVENT_DETAIL_FAIL,
    UPDATE_TASK_SUCCESS,
    UPDATE_TASK_FAIL,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAIL,
    ADD_TASK_SUCCESS,
    ADD_TASK_FAIL,
    ADD_MANAGER_SUCCESS,
    ADD_MANAGER_FAIL,
    MEMBERSHIP_TYPE_SUCCESS,
    MEMBERSHIP_TYPE_FAIL,
    UPDATE_MEMBER_PRICE_SUCCESS,
    UPDATE_MEMBER_PRICE_FAIL,
    ADD_MEMBER_PRICES_SUCCESS,
    ADD_MEMBER_PRICES_FAIL,
} from "../action/action";



    const intialState = {
        list : [],
        totalCount:null,
        singleVenueDetail:{},
        providerDetail: {},
        providerDetailSuccess: null,
        userDetail: {},
        authResetData: {},
        resetSuccess: false,
        bizDeleteEvent: false,
        deletedEvent: {},
        confirmEvent: false,
        confirmedEvent: {},
        declineEvent: false,
        declinedEvent: {},
        singleEventDetail: {},
        updateEventSuccess: null,
        updatedEvent: null,
        packageList:[],
        allAmenities:[],
        bookingSuccess: null,
        myBookings:[],
        bookingDetail:{},
        currentBooking: [],
        customerList: [],
        searchVenueInNavBar:[],
        wishList:[],
        wishListStatus: "",
        providerVenuesListing: [],
        chatNotes: [],
        overAllStatus : {},
        slotAlreadyExist : [],
        singleVenueReview : [],
        eachVenueDetail : [],
        draft_event: [],
        getDraft : [],
        CouponValue : [],
        paymentSucceeded :[],
        notificatioList:[],
        alldraftList :[],
        wallet :[],
        wallet_history :[],
        bank :[],
        reservationReports: [],
        updatedReservationReport: {},
        membershipTypes: [],
        membershipType: {},
        updatedMembershipType: {},
        memberships: [],
        updatedMembership: {},
        updatedMemberPrice: {},
        whitelist: [],
        domain:[],
        addedEmailRestrictionEntry: {},
        deletedEmailRestrictionEntry: {},
        eventTypes: [],
        addEventTypeSuccess: null,
        updateEventTypeSuccess: null,
        updatedEventType: null,
        eventTags: [],
        addEventTagSuccess: null,
        updateEventTagSuccess: null,
        updatedEventTag: null,
        managers: [],
        updatedManager: {},
        updateVenueSuccess: false,
        businessPlan: null,
        businessPlanSuccess: null,
        updatedTask: {},
    }

    const Apis = (state = intialState , action) => {
        const {type , data} = action
        switch(type){
            case GET_VENUES_LIST_SUCCESS:
                return {
                    ...state,
                    list : data["body"].list,
                    totalCount :data["body"].count
                }
            case SINGLE_VENUE_SUCCESS : {
                localStorage.setItem("id" , data["body"]?.id)
                return {
                    ...state,
                    singleVenueDetail : data["body"],
                }
            }
            case UPDATE_VENUE_SUCCESS: {
                return {
                    ...state,
                    updateVenueSuccess: true,
                }
            }
            case GET_PROVIDER_PROFILE_SUCCESS : {
                return {
                    ...state,
                    providerDetail : data["body"],
                    providerDetailSuccess: true,
                }
            }
            case GET_PROVIDER_PROFILE_FAIL : {
                return {
                    ...state,
                    providerDetailSuccess: false,
                }
            }
            case GET_USER_PROFILE_SUCCESS : {
                return {
                    ...state,
                    userDetail : data["body"],
                }
            }
            case GET_BUSINESS_PLAN_SUCCESS: {
                return {
                    ...state,
                    businessPlan: data["body"],
                    businessPlanSuccess: true,
                }
            }
            case GET_BUSINESS_PLAN_FAIL: {
                return {
                    ...state,
                    businessPlanSuccess: false,
                }
            }
            case GET_MANAGERS_SUCCESS: {
                return {
                    ...state,
                    managers: data["body"],
                }
            }
            case ADD_MANAGER_SUCCESS: {
                return {
                    ...state,
                    updatedManager: data["body"],
                }
            }
            case ADD_MANAGER_FAIL: {
                return {
                    ...state,
                    updatedManager: null,
                }
            }
            case UPDATE_MANAGER_ACCESS_SUCCESS: {
                return {
                    ...state,
                    updatedManager: data["body"],
                }
            }
            case UPDATE_MANAGER_ACCESS_FAIL: {
                return {
                    ...state,
                    updatedManager: null,
                }
            }
            case API_PACKAGES_SUCCESS : {
                return {
                    ...state,
                    packageList : data["body"],
                }
            }
            case ALL_AMENITIES_SUCCESS : {
                return {
                    ...state,
                    allAmenities : data["body"],
                }
            }
            case BOOK_VENUE_SUCCESS :{
                {
                    return {
                        ...state,
                        bookingSuccess : true,
                        bookingDetail : data["body"]
                    }
                }
            }
            case BOOK_VENUE_FAIL :{
                {
                    return {
                        ...state,
                        bookingSuccess : false
                    }
                }
            }
            case CURRENT_BOOKINGS_SUCCESS:{
                {
                    return {
                        ...state,
                        myBookings :  data["body"],
                    }
                }
            }
            case CUSTOMER_LIST_SUCCESS:{
                {
                    return {
                        ...state,
                        customerList : data["body"],
                    }
                }
            }
            case CALENDAR_DATA_SUCCESS : {
                {
                    return {
                        ...state,
                        currentBooking :  data["body"],
                    }
                }
            }
            case RESERVATION_REPORTS_SUCCESS : {
                {
                    return {
                        ...state,
                        reservationReports: data["body"],
                    }
                }
            }
            case ADD_RESERVATION_REPORT_SUCCESS: {
                {
                    return {
                        ...state,
                        updatedReservationReport: data["body"],
                    }
                }
            }
            case UPDATE_RESERVATION_REPORT_SUCCESS: {
                {
                    return {
                        ...state,
                        updatedReservationReport: data["body"],
                    }
                }
            }
            case DELETE_RESERVATION_REPORT_SUCCESS: {
                {
                    return {
                        ...state,
                        updatedReservationReport: data["body"],
                    }
                }
            }
            case MEMBERSHIP_TYPES_SUCCESS: {
                {
                    return {
                        ...state,
                        membershipTypes: data["body"],
                    }
                }
            }
            case MEMBERSHIP_TYPE_SUCCESS: {
                {
                    return {
                        ...state,
                        membershipType: data["body"],
                    }
                }
            }
            case MEMBERSHIP_TYPE_FAIL: {
                {
                    return {
                        ...state,
                        membershipType: null,
                    }
                }
            }
            case ADD_MEMBERSHIP_TYPE_SUCCESS: {
                {
                    return {
                        ...state,
                        updatedMembershipType: data["body"],
                    }
                }
            }
            case UPDATE_MEMBERSHIP_TYPE_SUCCESS: {
                {
                    return {
                        ...state,
                        updatedMembershipType: data["body"],
                    }
                }
            }
            case DELETE_MEMBERSHIP_TYPE_SUCCESS: {
                {
                    return {
                        ...state,
                        updatedMembershipType: data["body"],
                    }
                }
            }
            case MEMBERSHIPS_SUCCESS: {
                {
                    return {
                        ...state,
                        memberships: data["body"],
                    }
                }
            }
            case ADD_MEMBERSHIPS_SUCCESS: {
                return {
                    ...state,
                    updatedMembership: data["body"],
                }
            }
            case IMPORT_MEMBERSHIPS_SUCCESS: {
                return {
                    ...state,
                    updatedMembership: data["body"],
                }
            }
            case UPDATE_MEMBERSHIP_SUCCESS: {
                return {
                    ...state,
                    updatedMembership: data["body"],
                }
            }
            case DELETE_MEMBERSHIP_SUCCESS: {
                return {
                    ...state,
                    updatedMembership: data["body"],
                }
            }
            case ADD_MEMBER_PRICES_SUCCESS: {
                return {
                    ...state,
                    updatedMemberPrice: data["body"],
                }
            }
            case ADD_MEMBER_PRICES_FAIL: {
                return {
                    ...state,
                    updatedMemberPrice: null,
                }
            }
            case UPDATE_MEMBER_PRICE_SUCCESS: {
                return {
                    ...state,
                    updatedMemberPrice: data["body"],
                }
            }
            case UPDATE_MEMBER_PRICE_FAIL: {
                return {
                    ...state,
                    updatedMemberPrice: null,
                }
            }
            case WHITELIST_SUCCESS: {
                {
                    return {
                        ...state,
                        whitelist: data["body"],
                    }
                }
            }
            case DOMAIN_SUCCESS: {
                {
                    return {
                        ...state,
                        domain: data["body"],
                    }
                }
            }
            case ADD_WHITELIST_ENTRY_SUCCESS: {
                {
                    return {
                        ...state,
                        addedEmailRestrictionEntry: data["body"],
                    }
                }
            }
            case ADD_WHITELIST_ENTRY_FAIL: {
                {
                    return {
                        ...state,
                        addedEmailRestrictionEntry: null,
                    }
                }
            }
            case DELETE_WHITELIST_ENTRY_SUCCESS: {
                {
                    return {
                        ...state,
                        deletedEmailRestrictionEntry: data["body"],
                    }
                }
            }
            case DELETE_WHITELIST_ENTRY_FAIL: {
                {
                    return {
                        ...state,
                        deletedEmailRestrictionEntry: null,
                    }
                }
            }
            case EVENT_TYPES_SUCCESS : {
                {
                    return {
                        ...state,
                        eventTypes :  data["body"],
                    }
                }
            }
            case UPDATE_EVENT_TYPE_SUCCESS : {
                {
                    return {
                        ...state,
                        updateEventTypeSuccess: true,
                        updatedEventType: data["body"],
                    }
                }
            }
            case UPDATE_EVENT_TYPE_FAIL : {
                {
                    return {
                        ...state,
                        updateEventTypeSuccess: false,
                    }
                }
            }
            case ADD_EVENT_TYPE_SUCCESS : {
                {
                    return {
                        ...state,
                        addEventTypeSuccess: true,
                        updatedEventType: data["body"],
                    }
                }
            }
            case ADD_EVENT_TYPE_FAIL : {
                {
                    return {
                        ...state,
                        addEventTypeSuccess: false,
                    }
                }
            }
            case DELETE_EVENT_TYPE_SUCCESS : {
                {
                    return {
                        ...state,
                        updatedEventType: {},
                    }
                }
            }
            case EVENT_TAGS_SUCCESS : {
                {
                    return {
                        ...state,
                        eventTags :  data["body"],
                    }
                }
            }
            case UPDATE_EVENT_TAG_SUCCESS : {
                {
                    return {
                        ...state,
                        updateEventTagSuccess: true,
                        updatedEventTag: data["body"],
                    }
                }
            }
            case UPDATE_EVENT_TAG_FAIL : {
                {
                    return {
                        ...state,
                        updateEventTagSuccess: false,
                    }
                }
            }
            case ADD_EVENT_TAG_SUCCESS : {
                {
                    return {
                        ...state,
                        addEventTagSuccess: true,
                        updatedEventTag: data["body"],
                    }
                }
            }
            case ADD_EVENT_TAG_FAIL : {
                {
                    return {
                        ...state,
                        addEventTagSuccess: false,
                    }
                }
            }
            case DELETE_EVENT_TAG_SUCCESS : {
                {
                    return {
                        ...state,
                        updatedEventTag: {},
                    }
                }
            }
            case SEARCH_DATA_SUCCESS : {
                {
                    return {
                        ...state,
                        searchVenueInNavBar :  data["body"],
                    }
                }
            }
            case MANAGE_STATUS_BOOKING:{
                {
                    return {
                        ...state,
                        bookingSuccess : false,
                    }
                }
            }
            case GET_WISHLIST_SUCCESS : {
                return {
                    ...state,
                    wishList :  data["body"],
                }
            }
            case PROVIDER_VENUE_LISTING_SUCCESS:{
                return {
                    ...state,
                    providerVenuesListing :  data["body"],
                }
            }
            case CHAT_NOTES_SUCCESS:{
                return {
                    ...state,
                    chatNotes :  data["body"],
                }
            }
            case OVERALLSTATUS_SUCCESS :{
                return {
                    ...state,
                    overAllStatus :  data["body"],
                }
            }
            case  SLOT_ALREADY_EXIST_SUCCESS:{
                return {
                    ...state,
                    slotAlreadyExist :  data["body"],
                }
            }
            case SINGLE_VENUE_REVIEW_SUCCESS :{
                return {
                    ...state,
                    singleVenueReview :  data["body"],
                }

            }
            case EACH_VENUE_DETAIL_SUCCESS :{
                return {
                    ...state,
                    eachVenueDetail :  data["body"],
            }
            }
            case DRAFT_EVENT_SUCCESS:{
                return {
                    ...state,
                    draftEvent :  data["body"],
                }
            }
            case GET_DRAFT_EVENT_SUCCESS:{
                return {
                    ...state,
                    getDraft :  data["body"],
                }
            }
            case APPLYCOUPON_SUCCESS :{
                return {
                    ...state,
                    CouponValue :  data["body"],
                }
            }

            case WALLET_SUCCESS :{
                return {
                    ...state,
                    wallet : data["body"],
                }
            }

            case APPLYCOUPON_CLEAR :{
                return {
                    ...state,
                    CouponValue :  data["body"],
                }
            }

            case PAYMENTSUCCEED_SUCCESS :{
                return {
                    ...state,
                    paymentSucceeded :  data["body"],
                }
            }
            case GET_NOTIFICATION_SUCCESS :{
                return {
                    ...state,
                    notificatioList :  data["body"],
                }
            }
            case GET_ALL_DRAFT_VENUES_SUCCESS :{
                return {
                    ...state,
                    alldraftList :  data["body"],
                }
            }

            case WALLET_HISTORY_SUCCESS :{
                return {
                    ...state,
                    wallet_history :  data["body"],
                }
            }

            case BANK_SUCCESS :{
                return {
                    ...state,
                    bank: data["body"],
                }
            }

            case AUTH_RESET_SUCCESS :{
                return {
                    ...state,
                    authResetData: data["body"],
                }
            }

            case RESET_PWD_SUCCESS :{
                return {
                    ...state,
                    resetSuccess: true,
                }
            }

            case SET_RESET_PWD_FALSE_SUCCESS :{
                return {
                    ...state,
                    resetSuccess: false,
                }
            }

            case BIZ_DELETE_SUCCESS : {
                return {
                    ...state,
                    bizDeleteEvent: true,
                    deletedEvent: data['body'],
                }
            }

            case EVENT_DETAIL_SUCCESS: {
                return {
                    ...state,
                    singleEventDetail: data['body'],
                }
            }

            case EVENT_DETAIL_FAIL: {
                return {
                    ...state,
                    singleEventDetail: null,
                }
            }

            case CONFIRM_EVENT_SUCCESS : {
                return {
                    ...state,
                    confirmEvent: true,
                    confirmedEvent: data['body'],
                }
            }

            case DECLINE_EVENT_SUCCESS : {
                return {
                    ...state,
                    declineEvent: true,
                    declinedEvent: data['body'],
                }
            }

            case UPDATE_EVENT_SUCCESS : {
                return {
                    ...state,
                    updateEventSuccess: true,
                    updatedEvent: data['body'].updateData,
                }
            }

            case UPDATE_EVENT_FAIL : {
                return {
                    ...state,
                    updateEventSuccess: false,
                }
            }

            case ADD_TASK_SUCCESS: {
                return {
                    ...state,
                    updatedTask: data['body'],
                }
            }

            case ADD_TASK_FAIL: {
                return {
                    ...state,
                    updatedTask: null,
                }
            }

            case UPDATE_TASK_SUCCESS: {
                return {
                    ...state,
                    updatedTask: data['body'],
                }
            }

            case UPDATE_TASK_FAIL: {
                return {
                    ...state,
                    updatedTask: null,
                }
            }

            case DELETE_TASK_SUCCESS: {
                return {
                    ...state,
                    updatedTask: data['body'],
                }
            }

            case DELETE_TASK_FAIL: {
                return {
                    ...state,
                    updatedTask: null,
                }
            }

            case CALENDAR_RESET_SUCCESS : {
                return {
                    ...state,
                    bizDeleteEvent: false,
                    confirmEvent: false,
                    declineEvent: false,
                }
            }

            case ADD_WISHLIST_SUCCESS : {
                return {
                    ...state,
                    wishListStatus: data["body"],
                }
            }

            default:
                return state;
        }
    }

export default Apis