import {
    CRow,
    CCol,
    CWidgetStatsA,
    CWidgetStatsD,
    CCard,
    CCardBody,
    CWidgetStatsB
} from "@coreui/react"
import CIcon from "@coreui/icons-react";
import {cibFacebook, cilArrowTop} from "@coreui/icons";
import {setAlert} from "../../redux/action/action";
import {useDispatch, useSelector} from "react-redux";
import {CChartBar, CChartLine} from "@coreui/react-chartjs";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {users} from "constants";

const AnalyticReports = () => {
    document.title = "Analytic Reports | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Take a deep dive into the analytic reports of your event venues at DoubleSpot."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const userDetail = useSelector(state => state.Apis.userDetail);

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.analyticsAccess) {
                dispatch(setAlert('You do not have the permissions to analytic reports.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    return (
        <div className="p-4 vw-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Analytic Reports</h2>
                    <p className="modal-subtitle mb-0">This page is for demo purposes only. The actual reports will be adjusted to fit each organization's needs.</p>
                    <hr/>
                    <CRow className="h-100 mb-4">
                        <CCol sm={6}>
                            <CWidgetStatsA
                                className="h-100 mb-4"
                                color="info"
                                value={
                                    <>
                                        30&nbsp;
                                        <span className="fs-6 fw-normal">
                                            (25.0% <CIcon icon={cilArrowTop} />)
                                        </span>
                                    </>
                                }
                                title="Number of rentals"
                                chart={
                                    <CChartLine
                                        className="mt-3 mx-3"
                                        style={{ height: '100px' }}
                                        data={{
                                            labels: ['July', 'August', 'September', 'October', 'November', 'December'],
                                            datasets: [
                                                {
                                                    label: '',
                                                    backgroundColor: 'transparent',
                                                    borderColor: 'rgba(255,255,255,.55)',
                                                    pointBackgroundColor: '#39f',
                                                    data: [26, 23, 24, 30, 24, 30],
                                                },
                                            ],
                                        }}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    border: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        display: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    min: 20,
                                                    max: 35,
                                                    display: false,
                                                    grid: {
                                                        display: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                            elements: {
                                                line: {
                                                    borderWidth: 1,
                                                },
                                                point: {
                                                    radius: 4,
                                                    hitRadius: 10,
                                                    hoverRadius: 4,
                                                },
                                            },
                                        }}
                                    />
                                }
                            />
                        </CCol>
                        <CCol sm={6}>
                            <CWidgetStatsA
                                className="h-100 mb-4"
                                color="danger"
                                value={
                                    <>
                                        0-10 years
                                    </>
                                }
                                title="Age group with highest program attendance"
                                chart={
                                    <CChartBar
                                        className="mt-3 mx-3"
                                        style={{ height: '100px' }}
                                        data={{
                                            labels: [
                                                '0-10',
                                                '11-20',
                                                '21-30',
                                                '31-40',
                                                '41-50',
                                                '51-60',
                                                '61-70',
                                                '71-80',
                                                '80+'
                                            ],
                                            datasets: [
                                                {
                                                    label: '',
                                                    backgroundColor: 'rgba(255,255,255,.2)',
                                                    borderColor: 'rgba(255,255,255,.55)',
                                                    data: [34.1, 14.8, 6.7, 13.9, 13.5, 11.0, 1.9, 2.0, 2.1],
                                                    barPercentage: 0.6,
                                                },
                                            ],
                                        }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    grid: {
                                                        display: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    max: 35,
                                                    border: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        display: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                }
                            />
                        </CCol>
                    </CRow>
                    <CRow className="h-100">
                        <CCol sm={6}>
                            <CWidgetStatsD
                                className="h-100"
                                icon={<CIcon className="my-4 text-white" icon={cibFacebook} height={52} />}
                                style={{ '--cui-card-cap-bg': '#3b5998' }}
                                values={[
                                    { title: 'followers', value: '8.5K' },
                                    { title: 'posts', value: '56' },
                                ]}
                            />
                        </CCol>
                        <CCol sm={6}>
                            <CWidgetStatsB
                                className="h-100"
                                progress={{ color: 'success', value: 31.1 }}
                                text="are using a fee waiver"
                                title="of program participants"
                                value="31.1%"
                            />
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </div>
    )
};

export default AnalyticReports;
