import {
    CButton,
    CCard,
    CCardBody,
    CDropdown,
    CDropdownDivider,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormInput,
} from "@coreui/react";
import {users} from "constants";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {cilPencil, cilPlus, cilTrash} from "@coreui/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteMembershipType,
    getMembershipType,
    getProviderVenueListing,
    setAlert,
    updateMemberPrice,
} from "../../../../redux/action/action";
import {useNavigate, useParams} from "react-router-dom";
import {columns} from "./FilterUtils";
import EditMemberPricingModal from "./EditMemberPricingModal";
import EditMembershipTypeModal from "../Members/EditMembershipTypeModal";

const MembershipType = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetail = useSelector(state => state.Apis.userDetail);
    const membershipType = useSelector(state => state.Apis.membershipType);
    const updatedMembershipType = useSelector(state => state.Apis.updatedMembershipType);
    const venueListings = useSelector(state => state.Apis.providerVenuesListing);
    const updatedMemberPrice = useSelector(state => state.Apis.updatedMemberPrice);

    const [membershipTypeDetails, setMembershipTypeDetails] = useState({});
    const [allPricings, setAllPricings] = useState([]);
    const [displayedPricings, setDisplayedPricings] = useState([]);
    const [venues, setVenues] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [editMemberPricingModalVisible, setEditMemberPricingModalVisible] = useState(false);
    const [editMemberPricingFormData, setEditMemberPricingFormData] = useState({});
    const [venueSearch, setVenueSearch] = useState("");

    const onEditButtonClick = () => {
        setEditModalVisible(true);
        setEditFormData({
            id,
            name: membershipTypeDetails.name,
        });
    }

    const onDeleteButtonClick = () => {
        if (window.confirm("Are you sure you want delete this membership type? Entries of customers with the membership type will be deleted as well.")) {
            dispatch(deleteMembershipType({id}));
        }
    }

    const onAddPricingButtonClick = () => {
        setEditMemberPricingFormData({
            id: null,
            free: false,
            membership_type_id: id,
        });
        setEditMemberPricingModalVisible(true);
    };

    const onEditPricingButtonClick = (pricing) => {
        const { id, process_payment, free_attendees, price_per_attendee, hourly_price, cleaning_fee } = pricing;
        setEditMemberPricingFormData({
            id,
            free: !process_payment,
            free_attendees,
            price_per_attendee,
            hourly_price,
            cleaning_fee,
        });
        setEditMemberPricingModalVisible(true);
    };

    const onDeletePricingButtonClick = (id) => {
        if (window.confirm("You are about to remove the member price. Are you sure to continue?")) {
            dispatch(updateMemberPrice({
                id,
                deleted: true,
            }));
        }
    };

    useEffect(() => {
        dispatch(getProviderVenueListing());
    }, []);

    useEffect(() => {
        if (updatedMembershipType?.deleted) {
            navigate("/business/customers/members");
            return;
        }
        const membershipTypeId = parseInt(id);
        dispatch(getMembershipType({
            id: membershipTypeId,
        }));
        setEditModalVisible(false);
        setEditMemberPricingModalVisible(false);
    }, [updatedMembershipType, updatedMemberPrice]);

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role === users.ROLE_VENUE_OWNER) {
            } else if (userDetail?.manager?.editCustomerAccess) {
            } else {
                dispatch(setAlert('You do not have the permissions to view membership types.','error'));
                navigate("/business/");
            }
        }
    }, [userDetail]);

    useEffect(() => {
        setVenues(venueListings);
    }, [venueListings]);

    useEffect(() => {
        if (membershipType === null) {
            navigate("/business/customers/members");
            return;
        }
        setMembershipTypeDetails(membershipType);
        document.title = `${membershipType.name} | DoubleSpot Business`;
        document.querySelector('meta[name="description"]').setAttribute(
            "content",
            `View membership type "${membershipType.name}" with DoubleSpot's business dashboard.`
        );
    }, [membershipType]);

    useEffect(() => {
        const pricings = membershipType?.member_prices || [];
        setAllPricings(pricings);
        let newDisplayedPricings = membershipType?.member_prices || [];
        if (venueSearch) {
            const searchTerm = venueSearch.toLowerCase();
            newDisplayedPricings = newDisplayedPricings.filter(entry => entry.venue?.name.toLowerCase().includes(searchTerm));
        }
        setDisplayedPricings(newDisplayedPricings);
    }, [membershipTypeDetails, venueSearch]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <h2 className="mb-0">{membershipTypeDetails?.name}</h2>
                        <CDropdown>
                            <CDropdownToggle color="light">Actions</CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem role="button" onClick={onEditButtonClick}>
                                    <CIcon icon={cilPencil} className="primary-color"/> &nbsp; Edit
                                </CDropdownItem>
                                <CDropdownDivider/>
                                <CDropdownItem role="button" onClick={onDeleteButtonClick}>
                                    <CIcon icon={cilTrash} className="primary-color"/> &nbsp; Delete
                                </CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </div>
                    <hr/>
                    <h4 className="mt-4">Member Pricing</h4>
                    <p class="modal-subtitle">If the customer has multiple membership status, the lowest member price for each fee will be applied.</p>
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <CFormInput placeholder="Search..." onChange={
                                (event) => setVenueSearch(event.target.value)
                            }/>
                        </div>
                        <div>
                            <CButton color="light" onClick={onAddPricingButtonClick}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Member Pricing
                            </CButton>
                        </div>
                    </div>
                    <DataTable
                        columns={columns(onEditPricingButtonClick, onDeletePricingButtonClick)}
                        data={displayedPricings}
                    />
                </CCardBody>
            </CCard>
            <EditMembershipTypeModal
                visible={editModalVisible}
                onClose={() => setEditModalVisible(false)}
                editFormData={editFormData}
                setEditFormData={setEditFormData}
                dispatch={dispatch}
            />
            <EditMemberPricingModal
                visible={editMemberPricingModalVisible}
                onClose={() => setEditMemberPricingModalVisible(false)}
                editFormData={editMemberPricingFormData}
                setEditFormData={setEditMemberPricingFormData}
                venues={venues}
                allPricings={allPricings}
                dispatch={dispatch}
            />
        </div>
    );
};

export default MembershipType;
